@keyframes buttonTouched {
  0%, 100% {
  }
  50% {
    background-color: #e1e1e1;
  }
}

@keyframes fillProcessBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }

}

.fade-enter {
  opacity: 0;

}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: .4s opacity ease-in-out;
  will-change: opacity;
}

.fade-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  pointer-events: none;
  transition: .4s opacity ease-in-out;
  will-change: opacity;

}
