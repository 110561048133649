.qr-reader {
  height: 180px;
  width: 180px;
  border: 1px solid #000;
  margin: 0 auto 1.5rem auto;

  > section {
    > section {
      > div {
        border-width: 0px !important;
      }
    }
  }
}