html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

p {
  margin: .75rem 0;
}

.text-left {
  text-align: left;
}

.h100 {
  height: 100%;
}

.center {
  text-align: center;
}

.d-flex {

  &.column {
    flex-direction: column;
  }
}

.expanded {
  flex: 1;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-center-main {
  justify-content: center;
  align-items: start;
}

.flex-center-cross {
  align-items: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-center-y {
  align-items: center;
}

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.color-blue {
  color: $blue;
}