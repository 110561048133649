//.app {

//  @media (max-width: 560px) {
//    position: relative;
//  }
//}

.screen {
  position: relative;
  z-index: 99;
  min-height: 100vh;
  background: #fff;

  &.verify, &.login {
    header.curve .title {
      position: absolute;
      padding: 1.5rem 0;
    }
    .fixed-title {
      display: none;
    }
  }
}


.screen.scaffold {
  display: flex;
  flex-direction: column;

  .wrapper {
    flex: 1;
  }
}

.screen.splash {
  position: fixed;
  left: 0;
  top: 0;
  background: url("../images/splash.jpg") center / cover no-repeat;
  width: 100%;
  height: 100%;

  .process {
    position: absolute;
    bottom: 4rem;
    left: 50%;
    height: 10px;
    background: #F3F3F7;
    transform: translateX(-50%);
    width: 200px;
    z-index: 99;
    border-radius: 100px;


    div {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 0;
      background: $red;
      border-radius: 100px;
      animation: fillProcessBar 3s forwards ease-in-out;
    }
  }
}

.screen.verify {
  .wrapper {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }

  .digits {
    margin-bottom: .5rem;

    div {
      border-radius: 100px;
      width: 21px;
      height: 21px;
      background: #DEDEDE;
      margin: 0 .5rem;
      transition: .5s;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 12px;

      &.en {
        background: $red;
      }
    }
  }

  .keyboard {
    direction: ltr;
    flex: 1;
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //margin: .5rem 0 0 0;
    //grid-gap: .75rem;
    &.loading {
      pointer-events: none;
    }

    button {
      position: relative;
      border-radius: 8px;
      border: 1px solid #E8E8E8;
      height: 62px;
      font-size: 18px;
      background-color: #F5F6FA !important;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      float: left;
      width: 31.33%;
      margin: 1%;
      transition: .2s opacity;

      &.loading {
        opacity: 0.4;
      }

      &.done {
        background: $red url("../images/next.png") center no-repeat !important;;
        color: #fff;
        box-shadow: 0 2px 10px rgba(#000, .1), 0 5px 10px rgba($red, .15);
        border: none;
      }

      &.backspace {
        background: url("../images/back.png") center / 20px auto no-repeat;
      }

      &.touched:not(.done) {
        animation: .15s ease-in-out buttonTouched;
      }
    }
  }
}
