textarea, button, input {
  line-height: 1.5;
  font-size: 100%;
  border: 0;
  outline: none;
  font-family: IRANSans;
}

input.form-control {
  background: #F5F5F5;
  border-radius: 5px;
  color: #777;
}

textarea.form-control {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  width: 100%;
  height: 100px;
  resize: none;
  padding: .75rem;
  font-size: 12px;

  &:focus {
    border-color: $blue;
  }
}

.btn {
  padding: 1rem 0;
  max-width: 100%;
  width: 230px;
  display: inline-block;
  border-radius: 6px;
  transition: .2s opacity;

  &.loading, &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }

  &.red {
    background: $red;
    font-weight: bold;
    color: #fff;
  }

  &.blue {
    background: linear-gradient(180deg, #044b7c, #4688e3);
    color: #fff;
  }

  &.rounded {
    border-radius: 100px;
  }
}

header.label {
  margin-bottom: .5rem;

  img {
    vertical-align: middle;
    margin-left: .5rem;
  }
}
