@import '~bootstrap/scss/bootstrap-grid',
"variables",
"fonts",
"animations",
"dashboard",
"sidebar",
"main",
"forms",
"screens",
"qr-reader";


body {
  background: #044b7c;
  direction: rtl;
  font-family: IRANSans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #404040;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
    max-width: 720px;
    margin: 0 auto;

  &.white-bg{
    background: #fff;
  }
  &.overflow-hidden {
    overflow: hidden;

    button.go-back {
      opacity: 0;
      transform: scale(.5);
    }
  }

  &.dark-statusbar {
    .hamburger-menu-btn {
      background: #fff;

      span {
        background: #404040;
        box-shadow: 1px 1px 4px rgba(#fff, 0.5);
      }
    }

    button.go-back {
      filter: invert(1);
    }
  }

  &.modal-open {
    .hamburger-menu-btn {
      background: none;
    }

    .overlay {
      z-index: 999;
    }
  }

}

#root {
  &.navigating {
    height: 100vh;
    overflow: hidden;
  }
}


.wrapper {
  padding: 1.25rem;
  padding-bottom: 2.5rem;

  @media (max-width: 320px) {
    padding: 1rem;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(#111522, .4);
  z-index: 990;
}

header.curve {
  position: relative;
  background: linear-gradient(0deg, #4688e3, #044b7c);
  color: #fff;
  padding: 3.5rem 1rem 3rem 1rem;
  z-index: 1;
  width: 100%;
  font-weight: bold;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 30px;
    background: url("../images/header-curve.svg") bottom / cover no-repeat;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/logo_patt.png") top left repeat;
    z-index: -1;
  }


  .title {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 16px;
  }

  .form {
    .label {
      font-size: 14px;
      padding: 0 .5rem .5rem 0;
    }

    input.form-control {
      text-align: center;
      width: 100%;
      padding: .75rem 0;
      direction: ltr;

      &:focus {
        background: #fff;
      }
    }

  }
}

.fixed-title {
  position: fixed;
  z-index: 800;
  left: 0;
  top: 0;
  width: 100%;
  background: linear-gradient(0deg, #4688e3, #044b7c);
  padding: 1rem 0;
  font-size: 16px;
  text-align: center;
  color: #fff;
  transition: .3s;
  font-weight: bold;

  &:not(.visible) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/logo_patt.png") top left repeat;
    z-index: -1;
  }
}

header.normal {
  position: relative;
  background: linear-gradient(0deg, #4688e3, #044b7c);
  padding: 3rem 1rem 2rem 1rem;
  color: #fff;
  text-align: center;
  z-index: 1;

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/logo_patt.png") top left repeat;
    z-index: -1;
  }
}

button.go-back, .btn-filter {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  z-index: 990;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  transition: .2s opacity, .2s transform;
}

.hint-box {
  position: relative;
  background: #fff;
  border-radius: 12px;
  text-align: center;
  padding: 2rem 0;
  color: #7c8188;
  box-shadow: 0 0 60px -10px rgba(47, 51, 68, 0.28);
  width: 100%;
  max-width: 350px;
  margin: 0 auto 5rem auto;
}

.loading-spinner {
  position: relative;
  background: #fff;
  border-radius: 12px;
  text-align: center;
  padding: 2rem 0;
  font-size: 12px;
  color: #7c8188;
  box-shadow: 0 0 60px -10px rgba(47, 51, 68, 0.28);
  width: 100%;
  max-width: 350px;
  margin: 0 auto 5rem auto;

  .spinner {
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;
    z-index: 2;
    margin: 0 auto .5rem auto;

    .path {
      stroke: #dee3ea;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  .label {

  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.add-to-home {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background: #fff;
  z-index: 9999;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 30px rgba(#111, .1);
  text-align: center;
  pointer-events: none;
  visibility: hidden;
  transition: .5s;
  opacity: 0;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }

  header {
    margin-bottom: .5rem;
    color: #888;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button.close {
    width: auto;
    padding: .5rem 1.5rem;
    background: #e5e5e5;
  }

  button.add {
    background: $blue;
    color: #fff;
    opacity: 1 !important;
    padding: .5rem 1rem;
    width: auto;
  }
}

.message-success {
  padding: 1rem 1.5rem;
  margin: .75rem 0;
  border-radius: 10px;
  background-color: #47c93a;
  color: #fff;
  box-shadow: 0px 15px 40px -10px rgba(70, 201, 58, 0.9);
}
