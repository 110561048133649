.dashboard {}

.screen.home {
  .wrapper {
    padding: 1rem .75rem;
  }

  .page-card.gift {
    margin-bottom: .75rem;
  }

  .page-card.gift:last-of-type {
    margin-bottom: 1.5rem;
  }

  header.normal {
    .image {
      border: 4px solid $red;
      border-radius: 100px;
      display: block;
      width: 65px;
      margin: 0 auto .25rem auto;

      img {
        border-radius: 100px;
        width: 100%;
        min-height: 57px;
        display: block;
      }
    }

    .details {
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      margin-top: 1rem;

      >.item {
        width: 50%;
        margin-bottom: .75rem;
        display: flex;
        line-height: 1.3;
        font-size: 13px;
        align-items: center;

        >div {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: auto 0;
        }

        .label {
          font-weight: 400;
        }

        .value {
          font-size: 12px;
          font-weight: 500;
          display: block;
        }

        &:nth-child(odd) {
          padding-left: .25rem;
        }

        &:nth-child(even) {
          padding-right: .25rem;
        }

        &:last-child {
          padding: 0;
          width: 100%;


          .value,
          .label {
            display: inline;
          }
        }

        img {
          width: 20px;
          height: auto;
          vertical-align: middle;
          margin-left: .5rem;
        }
      }
    }
  }

}

/// Lists

.list-view {
  position: relative;
  padding-top: 0 !important;
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;

  z-index: 2;
  //max-height: 85vh;
  //overflow: auto;

  .page-card {
    font-size: 11px;

    &:not(:last-of-type) {
      margin-bottom: .75rem;
    }

    b {
      font-weight: bold;
      font-size: 100%;
    }

    .body {
      margin-top: 0;
      box-shadow: 0 0 7px rgba(#000, .12);
    }
  }
}

.page-card {
  position: relative;
  font-size: 10px;
  line-height: 2;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  &.single-line {
    text-align: center;
    font-size: 12px;

    .body {
      display: flex;

      >div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  b {
    font-size: 110%;
  }


  .inner>header {
    font-size: 14px;

    a {
      text-decoration: none;
      display: inline-block;
    }
  }

  .color {
    position: absolute;
    left: 100%;
    top: 5px;
    bottom: 5px;
    width: 5px;
    border-radius: 100px;
  }

  .body {
    position: relative;
    padding: 1rem;
    box-shadow: 0 5px 10px rgba(#000, .15);
    margin-top: .5rem;
    border-radius: 8px;
    background: #fff;
  }

  .link {
    color: #828282;

    img {
      vertical-align: middle;
    }

    a {}
  }
}


.image-card {
  position: relative;
  font-size: 12px;
  background: linear-gradient(270deg, #FAFAFA, #F3F3F3, #E9E9E9);
  border-radius: 10px;
  padding: .5rem;
  line-height: 1.7;
  overflow: hidden;
  z-index: 1;

  .image {
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px rgba(#222, .2);
    border-radius: 100px;

    img {
      border-radius: 100px;
      width: 60px;
      display: block;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    background: url("../images/image-card.svg") left center / auto 100% no-repeat;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  header {
    margin-bottom: .5rem;
    margin-right: .5rem;
    font-size: 16px;
  }

  .details {
    span {
      display: inline-block;
    }

    .label {
      margin-left: .25rem;
    }

    .icon {
      width: 20px;
      vertical-align: middle;

      img {
        width: 12px;
        height: auto;
        display: block;
      }
    }
  }
}

.screen.about-us {
  header.main {
    position: relative;
    height: 300px;
    overflow: hidden;
    background: url("../images/about-us.jpg") center center / cover;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #2B2F3B;
      opacity: .3;
    }
  }

  // header.main,
  // header.title::before {}

  header.title {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    color: #fff;
    z-index: 2 !important;
    overflow: hidden;
    box-shadow: 0 -10px 30px rgba(#000, .3);
    backdrop-filter: blur(10px);

    &,
    &::after,
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      border-radius: 20px 20px 0 0;
      content: '';
      z-index: -1;
    }

    &::before {
      filter: blur(15px);
      opacity: .9;
    }

    &::after {
      background: linear-gradient(180deg, #044b7c, #4688e3);
      opacity: .7;
    }

    .logo {
      box-shadow: 0 0 5px rgba(#000, .2);
      border-radius: 100px;
      margin-left: .75rem;
      width: 50px;
      height: 50px;
      background: #fff;
      text-align: center;

      img {
        display: inline-block;
        height: 100%;
        width: auto;
        border: 4px solid #fff;
        border-radius: 100px;
      }
    }

  }

  .wrapper {
    .logo {}

    .content {
      margin-top: .5rem;
      font-size: 12px;
    }
  }
}

.screen.ethics {

  header.main {
    background: url("../images/manshoor.jpg") center bottom / cover;
    height: 380px;
  }

  .content {
    &:not(:last-of-type) {
      margin-bottom: .75rem;
    }

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 8px;
      width: 8px;
      background-color: $red;
      border-radius: 100px;
      margin-left: .5rem;
      box-shadow: 0 5px 5px rgba(#000, .1);
    }
  }
}

.screen.contact-us {
  font-size: 14px;

  .content {
    position: relative;
    padding: 1.5rem 1rem;
  }

  .tab-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  .tabs>header {
    position: relative;
    background: linear-gradient(180deg, #044b7c, #4688e3);
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 20px rgba(#222, .3);
    height: 60px;
    line-height: 60px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(#000, .2);
    padding: 0 1rem;

    div.item {
      width: 50%;
      transition: .5s;

      &.active {
        color: $red;

        &:nth-child(2)+.spinner {
          left: 1rem;
          right: 50%;
        }
      }
    }

    .spinner {
      position: absolute;
      height: 4px;
      bottom: -2px;
      background: $red;
      left: 50%;
      right: 1rem;
      z-index: 9;
      transition: .5s;
    }
  }

  .btn {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  .map {
    height: 300px;
    background: url("../images/map.jpg") center bottom;
  }

  .details {

    li {
      margin-bottom: 1.25rem;

      img {
        display: inline-block;
        vertical-align: middle;
        margin-left: .5rem;
        width: 15px;
      }

      a {
        display: inline-block;
        color: #404040;
      }
    }
  }

  form {}
}

.screen.catalog {
  .wrapper {}

  .posts {
    display: flex;
    flex-wrap: wrap;

    a {
      color: inherit;
    }

    article {
      width: 50%;
      padding: .25rem;
      font-size: 12px;
      text-align: center;

      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }

      .inner {
        background: #fff;
        border-radius: 8px;
        padding: .75rem;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          box-shadow: 30px 20px 60px 0 rgba(0, 0, 0, .04), -25px 0 30px 0 rgba(0, 0, 0, .02), 0 1px 1px rgba(0, 0, 0, .04);
          z-index: -2;
          width: 100%;
          height: 100%;
          left: 0;
          border-radius: 8px;
          top: 0;
        }

      }

      img {
        width: 100%;
        display: block;
        margin-bottom: .25rem;
      }

      .title {
        font-weight: bold;
      }

      .sub-title {
        font-size: 11px;
      }
    }
  }
}

.catalog-categories {
  background: linear-gradient(180deg, #044b7c, #4688e3);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  color: #fff;
  transform: translate3d(0, 100%, 0);
  transition: .3s ease-in-out;
  z-index: 997;

  ul {
    max-height: 250px;
    overflow: auto;
    display: block;
  }

  &.active {
    box-shadow: 0 -10px 30px rgba(49, 53, 66, 0.4);
    border-radius: 20px 20px 0 0;
    transform: translate3d(0, 0, 0);
    will-change: transform, border-radius, box-shadow;
  }

  header {
    text-align: center;
    margin: .5rem 0;
    font-size: 14px;
    font-weight: bold;
  }

  li {
    position: relative;
    margin: .75rem .5rem;
    padding-bottom: .75rem;
    color: #fff;
    font-size: 13px;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(#EDEDED, .1);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      height: 7px;
      width: 1px;
      background: #fff;
      transform: rotate(45deg);
      transform-origin: bottom;
      border-radius: 50px;
    }

    &::before {
      transform: rotate(-45deg);
    }

  }

  a {}
}

.screen.blog {
  .posts {
    background: #fff;
    border-radius: 20px 20px 0 0;
    padding-top: 1.25rem !important;
    min-height: 200px;

    a {
      color: inherit;
    }

    article {
      &:not(:last-of-type)::after {
        content: '';
        border-bottom: 1px solid #eee;
        display: block;
        margin: 1.25rem;
      }

      img {
        width: 100%;
        display: block;
      }

      header {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-bottom: .75rem;

        .image {
          border-radius: 100px;
          border: 2px solid $blue;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: .5rem;
          overflow: hidden;
        }

        .date {
          color: #777;
          font-size: 12px;
        }
      }

      .poster {
        margin-bottom: .5rem;

        img {
          border-radius: 8px;
        }
      }

      h3 {
        font-size: 16px;
        margin-bottom: .25rem;
        margin-top: .75rem;
      }

      .content {
        color: #444;
        font-size: 12px;
      }

    }
  }
}

.screen.suggestions {
  .btn {
    display: block;
    width: 100%;
    margin-top: 1rem;
  }

  .label {
    margin-bottom: .5rem;

    img {
      vertical-align: middle;
    }
  }

  input.form-control {
    border-radius: 10px;
    border: 1px solid #DFDFDF;
    width: 100%;
    resize: none;
    padding: .75rem;
    background: #fff;
    font-size: 12px;

    &:focus {
      border-color: $blue;
    }
  }

}

.screen.certificates {

  .posts {
    display: flex;
    flex-wrap: wrap;

    article {
      width: 50%;
      padding: 0 .5rem 1rem .5rem;
      font-size: 12px;
      text-align: center;

      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }

      .inner {
        padding: .5rem;
        background: #fff;
        border-radius: 5px;
      }

      img {
        width: 100%;
        display: block;
        margin-bottom: .25rem;
        border-radius: 6px;
        height: auto;
      }

      .title {
        font-weight: bold;
        padding: .25rem 0;
      }

      .sub-title {
        font-size: 11px;
      }
    }
  }
}

.screen.map {
  .address {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .75rem;
  }
}


.modal-box {
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-wrapper {
    position: relative;
    width: 85%;
    max-width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(#101524, .16);
    padding: 2rem 2rem;
    z-index: 1010;
    text-align: center;

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      width: 50px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 50%;
        height: 1px;
        border-radius: 2px;
        width: 20px;
        background: #9399a7;
        display: block;
        transform: rotate(45deg) translate3d(8px, -8px, 0);
      }

      &::after {
        transform: rotate(-45deg) translate3d(8px, 8px, 0);
      }
    }

    img {
      width: 40px;
      display: inline-block;
      height: auto;
    }

    header {
      font-weight: bold;
      color: #222;
    }

    .content {
      font-size: 14px;
      padding: .5rem 0 1rem 0;
    }

    .btn {
      padding: .75rem 2rem;
      width: auto;
      border-radius: 12px;
      font-size: 14px;
    }
  }
}

.modal-uploader {
  .modal-wrapper {
    img {
      border-radius: 100px;
      width: 50px;
    }

    .btn {
      position: relative;
      padding: 0;
      width: 200px;
      line-height: 45px;
      height: 45px;

      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
  }


  #image {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 45px;
    margin: 0;
    opacity: 0;
  }
}


.screen.single {
  header.main {
    position: relative;
    overflow: hidden;

    img {
      opacity: 0;
      width: 100%;
      display: block;
      height: auto;
    }

  }

  header.title {
    display: flex;
    padding: 0 2rem;
    align-items: center;
    color: #fff;
    z-index: 2 !important;
    overflow: hidden;
    box-shadow: 0 -10px 30px rgba(#000, .3);

    &,
    &::after,
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70px;
      border-radius: 20px 20px 0 0;
      content: '';
      z-index: -1;
    }

    &::before {
      filter: blur(15px);
      opacity: .9;
    }

    &::after {
      background: linear-gradient(180deg, #044b7c, #4688e3);
      opacity: .7;
    }
  }

  .additional {
    h2 {
      margin-top: 1rem;
      margin-bottom: .75rem;
      font-size: 16px;
      font-weight: bold;

      &::before {
        content: '';
        background: #044b7c;
        height: 8px;
        width: 8px;
        display: inline-block;
        vertical-align: middle;
        margin-left: .5rem;
        border-radius: 100px;
      }
    }

    .images {
      img {
        display: block;
        max-width: 100%;
        width: auto;
        margin: .25rem auto;
        height: auto;
      }
    }

    .videos {
      >div {
        margin-bottom: .5rem;
      }
    }

    .voices {
      audio {
        width: 100%;
        display: block;
        margin-bottom: .75rem;
      }
    }
  }
}

.screen.poll {
  .questions {
    .question {
      .label {
        font-weight: bold;
        margin-bottom: .25rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #eee;
      }
    }

    .question {
      text-align: center;

      button {
        outline: none;
        border: none;
        background: transparent;
        padding: 0;

        &:not(:last-of-type) {
          margin-left: .25rem;
        }
      }

      img {
        display: inline-block;
        width: 30px;
        height: auto;
      }
    }
  }
}