.hamburger-menu-btn {
  position: fixed;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: .1s;

  span {
    display: block;
    width: 19px;
    height: 2px;
    border-radius: 16px;
    background: #fff;
    margin-bottom: .25rem;
    box-shadow: 1px 1px 4px rgba(#333, .5);
    transition: .1s;

    &:last-of-type {
      margin-bottom: 0;
      width: 12px;
      margin-left: 5px;
    }
  }
}


.sidebar-wrapper {
  position: fixed;
  top: 0;
  width: 260px;
  max-width: 100%;
  height: 100%;
  right: 0;
  background: #fff;
  z-index: 999;
  transition: .2s ease-in-out;
  transition-property: transform, box-shadow;
  transform: translate3d(100%, 0, 0);
  -webkit-backface-visibility: hidden;
  overflow: auto;

  &.active {
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 40px rgba(#111522, .2);
    transition: .3s ease-in-out;
    will-change: transform, box-shadow;
  }

  li {
    &.has-child {
      > button.active {
        font-weight: bold;
      }
    }

    button.active {
      color: #000;


      & + ul {
        max-height: 300px;
      }

    }

    ul {
      max-height: 0;
      overflow: hidden;
      transition: .3s;
      margin-right: 1rem;
    }
  }

  .menu {
    padding: 1rem 0;

    button {
      display: block;
      color: #5C5C5C;
      text-decoration: none;
      padding: .5rem 1rem;
      font-size: 13px;
      width: 100%;
      text-align: right;
      background: none;
      transition: .1s;

      &:hover {
        background: #f5f5f5;
      }

      .image {
        display: inline-block;
        width: 30px;

        img {
          max-width: 20px;
          height: auto;
          vertical-align: middle;
        }
      }

      .text {
        display: inline-block;
      }
    }
  }


  .store-image {
    position: relative;
    box-shadow: 0 0 10px rgba(#222, .2);
    border-radius: 100px;
    margin: 0 auto 1.25rem auto;
    width: 70px;
    height: 70px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      display: block;
      box-shadow: 0 0 0 3px #fff;
    }

    .uploader {
      position: absolute;
      bottom: -12px;
      right: -3px;
      border-radius: 100px;
      background: #fff;
      padding: .35rem;

      img {
        width: 20px;
        height: auto;
        display: block;
      }
    }
  }
}
